.swiper {
  width: 120%;
  height: 100%;
  height: auto;
  z-index: 20;
}

.swiper .swiper-slide {
  padding-top: 5rem;
  padding-bottom: 2rem;
  z-index: 20;
  padding-left: 1px;
}

.swiper-pagination-bullet-active {
  bacground: #ccc;
}

.swiper-button-prev,
.swiper-button-next {
  color: #000;
  right: -0.87rem;
  padding: 0.6rem;
}
.swiper-button-next {
  margin-right: 0.6rem;
}
@media screen and (max-width: 738px) {
  .swiper-slider {
    display: block;
    justifiy-content: center;
    align-items: center;
    padding-left: 0.6rem;
  }

  .swiper-button-next {
    left: 18.6rem;
  }
}
