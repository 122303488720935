body {
  margin: 0;
  padding: 0;
}
a {
  color: #fff;
  text-decoration: none;
}
.tel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -40px;
  height: 50px;
  color: #fff;
}
.header {
  position: fixed;
  top: 0;
  z-index: 100;
  transition: background-color 0.3s ease; /* Smooth transition for changing background color */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 99vw;
}

.sticky {
  //position: fixed;

  top: 0;
  left: 0;
  width: 99vw;
  opacity: 50;
  background: #002060;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for a raised effect */
}
/* Resets */

nav-underline {
  width: 100%;
  display: block;
  float: left;
}

nav a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  display: block;
}
nav a:hover {
  text-decoration: none;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
nav ul li {
  margin: 0;
  padding: 0;
}

/* Top-level menu */
nav > ul > li {
  float: left;
  position: relative;
}
nav > ul > li > a {
  padding: 0px 1rem;
  display: block;
}

nav > ul > li:first-child {
  margin: 0;
}
nav > ul > li:first-child a {
  border: 0;
}

/* Dropdown Menu */
nav ul li ul {
  position: absolute;
  background-color: #002060;
  width: 100%;
  margin: 0;
  padding: 0;
  display: none;
}
nav ul li ul li {
  margin-top: 1rem;
  text-align: left;
  margin-left: -1rem;
  width: 100%;
}
nav ul li ul li a {
  padding: 0.4rem 0.4rem 0 0;
  font-size: 14px;
}
nav ul li:hover ul {
  display: block;
}

ul li a::after {
  position: absolute;
  content: "";
  top: 100%;
  left: 28px;
  width: 46%;
  height: 3px;
  background: #ffff00;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}
ul li a:hover {
  color: #ffff00;
}

ul li a:hover::after {
  transform: scaleX(1);
  transform-origin: left;
}

.btn {
  width: 8rem;
  height: 35px;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
  background: #ffffff;
  border-radius: 20px;
  color: #002060;
  margin-top: 0.1rem;
  &:hover {
    background-color: #fff;
  }
}

.links {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  whidth: 100wv;
  padding: 1rem 0.2rem;
  z-index: 2;
}

.links_logo {
  margin-right: 0.1rem;
  margin-left: 0.2rem;
}

.links_logo img {
  width: 110px;
  height: 60px;
}

.links_wrapper {
  display: flex;
  flex-direction: row;
  margin: auto 0;
}

.nav_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: red;
}

.links_wrapper ul li,
.nav_button li,
.nav_menu_wrapper li a {
  color: #333;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.nav_menu_wrapper li a {
  color: #fff;
}

.nav_button button,
.nav_menu_wrapper button {
  padding: 0.5rem 2rem;
  color: #fff;
  background-color: #000;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.nav_menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.nav_menu_wrapper {
  display: block;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: #000;
  padding: 2rem;
  position: absolute;
  top: 20px;
  right: 0;
  margin-top: 1rem;
  min-width: 230px;
  border-radius: 5px;
  box-shadow: 0 0 0 5 rgba(0, 0, 0, 0.2);
}

.nav_menu_wrapper li a {
  width: 100%;
  margin: 1rem 0;
  text-decoration: none;
  display: flex;
}

.nav_menu_wrapper_button {
  display: none;
}

.nav_menu_wrapper_links {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
}

.fa-caret-down {
  color: #fff;
  float: right;
  padding-right: 1px;
}
.inst {
  padding-right: 8px;
}

@media screen and (max-width: 1050px) {
  .container {
    padding: 2rem 4rem;
  }
  nav ul {
    flex-wrap: wrap;
  }

  .links_wrapper {
    display: none;
  }
  .nav_menu_wrapper {
    display: block;
  }
  .nav_menu {
    display: flex;
  }
}
@media screen and (max-width: 700px) {
  .btn {
    display: none;
  }
  .inst {
    display: none;
  }

  .links {
    flex-direction: column;
  }
}

@media screen and (min-width: 700px) {
  .tel {
    display: none;
  }
  .fa-caret-down {
    display: none;
  }
  nav ul li ul li a {
    padding: 2.4rem 0 0 0;
    font-size: 16px;
  }
}

@media screen and (max-width: 700px) {
  .header {
    background-color: #002060;
  }
  .container {
    padding: 1rem 1rem;
  }
  nav ul li ul {
    position: absolute;
    background-color: #002060;
    z-index: 2;
  }
  nav ul li ul li {
    margin-top: 0px;
    text-align: left;
    margin-left: 0;
    width: 20%;
  }
}

@media screen and (max-width: 550px) {
  .container {
    padding: 1rem 1rem;
  }

  .nav_button {
    display: none;
  }

  .nav_menu_wrapper {
    top: 20px;
  }

  .nav_menu_wrapper_button {
    display: block;
  }
}

@media screen and (max-width: 700px) {
  .nav-menu {
    display: flex;
  }
}
.nav_menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.nav_menu_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: #002060;
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0 0 0 5 rgba(0, 0, 0, 0.2);
}

.nav_menu_wrapper li a {
  margin: 1rem 0;
  text-decoration: none;
}

.nav_menu_wrapper_button {
  display: none;
}

@media screen and (max-width: 1050px) {
  .links_wrapper {
    display: none;
  }

  .nav_menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .header {
    padding: 2rem 4rem;
  }
  .nav_menu_wrapper {
    min-width: 50px;
  }
}

@media screen and (max-width: 550px) {
  .header {
    padding: 2rem 4rem;
  }
  .sticky {
    whidth: 80%;
  }
  .nav_button {
    display: none;
  }

  .nav_menu_wrapper {
    top: 20px;
  }
}

@media screen and (max-width: 360px) {
  .header {
    padding: 2rem 4rem;
  }
  .sticky {
    whidth: 80%;
  }
  .nav_button {
    display: none;
  }

  .nav_menu_wrapper {
    top: 20px;
  }
}
