.image {
  border-radius: 25px;
  transition: all 0.5s;
  cursor: pointer;
  margin-left: 18px;
  margin-bottom: 18px;
}
.image:hover {
  -webkit-transform: scale(0.4);
  transform: scale(1.1);
}
