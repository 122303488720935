body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: #e3e3e3;
  border: 2px solid #069;
  box-sizing: border-box;
  font-family: "Verdana";
  height: 100%;
  margin: 0 auto;  Centraliza 
  padding: 8px;
  width: 70%; /* Define a largura do Corpo do Documento */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
