.container {
  margin-top: -1rem;
  width: 18.7rem;
  margin-left: 3.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  @media (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.titulo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.6rem;
  color: #000;
  width: 16.3rem;
}

.tituloContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  color: #002060;
  font-size: 1.2rem;
  width: 15.6rem;
  font-weight: 600;
}

.form {
  max-width: 37.5rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.label {
  margin-left: 1rem;
}
.input {
  margin-bottom: 0.9rem;
  height: 1.8rem;
  border-radius: 4px;
  border: 0;
  padding: 0 8px;
  border: 3px solid #ccc;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  outline: none;
}
.input:focus {
  border: 3px solid #555;
}
.formulario {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  background: #fff;
  max-width: 18rem;
  margin: 0 auto 5px;
  padding: 1.8rem;
  text-align: center;
}
.labelA {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: "Roboto", sans-serif;
  outline: 0;
  width: 90%;
  border: 0;
  margin: 0 2px 0px 2px;
  padding: 4px;
  box-sizing: border-box;
  font-size: 0.8rem;
  font-weight: 400;
}

.button {
  width: 14rem;
  color: #ffffff;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
  background-color: #002060;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 20px;

  &:hover {
    background-color: #06238f;
  }
}

.Menssage {
  margin-top: 1rem;
  width: 13.2rem;
  height: 20vh;
  padding: 10px;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #add8e6;
  margin: 10px 10px;
}
.containerBtn {
  width: 18.7rem;
  margin-left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
