.containerzap {
  position: fixed;
  bottom: 305px;
  right: 8.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 80px;
  z-index: 99;
}
.pulsar img {
  width: 30px;
  height: 30px;
  background: transparent;
}
.pulsar {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1ca111;
  border-radius: 50%;
}

.pulsar:hover {
  cursor: pointer;
}
.pulsar:before,
.pulsar::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #1ca111;
  left: -10px;
  right: -10px;
  top: -10px;
  bottom: -10px;
  animation: pulse 1.5s linear infinite;
}

.pulsar::after {
  animation-delay: 0.5s;
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
@media (max-width: 768px) {
  .containerzap {
    bottom: 200px;
    right: 5rem;
    z-index: 199;
  }
}
@media (min-width: 768px) {
  .containerzap {
    bottom: 200px;
    right: 10rem;
    max-width: 120px;
  }
}
